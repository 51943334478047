.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #121a2d;
    color: white;
  }
  
  .error-image {
    max-width: 300px;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 36px;
    margin-bottom: 20px;
    color: #ff1493;
  }
  
  .back-home-button {
    background-color: #ff1493;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 18px;
    transition: background-color 0.3s ease;
  }
  
  .back-home-button:hover {
    background-color: #e01170;
  }
  