/* GiftsPage.css */

.gifts-page {
    padding-top: 80px;
    color: white;
    font-family: 'Roboto', sans-serif;
    background-color: #1d1f27;
    min-height: 100vh;
  }
  
  .gifts-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
  }
  .shift-right {
    margin-left: 280px;
  }
  .gifts-content h1 {
    font-size: 36px;
    color: #ff1493;
    margin-bottom: 20px;
  }
  
  .gifts-content p {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    margin-bottom: 20px;
  }
  
  button {
    padding: 12px 24px;
    background-color: #ff1493;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #e01170;
  }
  
  .gift-box {
    background-color: #282c34;
    padding: 30px;
    margin-top: 20px;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  }
  
  .gift-box h2 {
    font-size: 24px;
    color: #ff1493;
    margin-bottom: 10px;
  }
  
  .gift-box p {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  .gift-box img {
    max-width: 200px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
  }
  
  .gifts-history {
    margin-top: 30px;
  }
  
  .gifts-history h2 {
    font-size: 28px;
    color: #ff1493;
    margin-bottom: 10px;
  }
  
  .gifts-history ul {
    list-style-type: none;
    padding: 0;
  }
  
  .gifts-history li {
    font-size: 18px;
    color: rgba(255, 255, 255, 0.7);
    padding: 10px;
    background-color: #2d3038;
    margin-bottom: 10px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .gifts-history li:hover {
    background-color: #3b3e47;
  }
  
  .timer {
    font-size: 24px;
    color: #ff1493;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .gifts-content h1 {
      font-size: 28px;
    }
  
    .gifts-content p {
      font-size: 16px;
    }
  
    button {
      padding: 10px 20px;
      font-size: 14px;
    }
  
    .gift-box h2 {
      font-size: 20px;
    }
  
    .gift-box p {
      font-size: 18px;
    }
  
    .gifts-history h2 {
      font-size: 24px;
    }
  
    .gifts-history li {
      font-size: 16px;
    }
  
    .timer {
      font-size: 20px;
    }
  }
  