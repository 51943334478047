.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 6px;
    background: linear-gradient(135deg, #0c0e27, #3a3136);
    color: white;
    position: fixed;
    width: 100%;
    height: 47px;
    z-index: 1000;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.search-game-image {
  width: 20%;
  height: 20%;
  object-fit: contain;
}

/* Логотип */
.logo-container {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
}

.logo {
    width: 60px; /* Увеличено для лучшей видимости */
    height: auto;
}
.profile-section-container {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 12px;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.profile-section-container:hover {
    background-color: #5f3558; /* Darker background on hover */
}

.profile-section {
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: 8px;
}

.user-info {
    color: #ffffff;
    font-weight: bold;
    font-size: 14px;
}

.logout-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;
}

.logout-icon {
    width: 22px;
    height: 22px;
    transition: transform 0.3s, filter 0.3s;
}

.logout-button:hover .logout-icon {
    transform: scale(1.1);
    background-color: transparent;
}
.logo-text {
    font-size: 20px;
    font-weight: bold;
    color: #ff1493;
    margin-left: 12px; /* Добавлено больше расстояния от логотипа */
}

/* Центрированные элементы навигации */
.navbar-center {
    display: flex;
    gap: 40px; /* Увеличен промежуток для более приятного расположения */
}

.menu-item {
    color: white;
    text-decoration: none;
    font-size: 18px;
    padding: 10px 0;
    transition: color 0.3s;
}

.menu-item.active,
.menu-item:hover {
    color: #ff1493;
    border-bottom: 2px solid #ff1493; /* Подчеркивание при наведении */
}

/* Правая часть навбара */
.navbar-right {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-right: 30px; /* Слегка уменьшено для равномерности */
}

.search-box {
    position: relative;
    display: flex;
    align-items: center;
    background: #444;
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .search-box input {
    background: none;
    border: none;
    color: white;
    outline: none;
    font-size: 14px;
    width: 150px;
  }
  
  .search-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  .search-results {
    position: absolute;
    top: 40px;
    background-color: #1a1a1a;
    color: white;
    width: 220%;
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    z-index: 10;
    padding: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
    RIGHT: -250PX;
  }
  
  .search-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #333;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .search-item:last-child {
    border-bottom: none;
  }
  
  .search-item:hover {
    background-color: #333;
  }
  
  .product-icon {
    width: 75px;
    height: 50px;
    border-radius: 5px;
    margin-right: 15px;
  }
  
  .product-details {
    display: flex;
    flex-direction: column;
  }
  
  .product-info-search {
    display: flex;
    align-items: center;
    font-size: 14px;
  }
  
  .product-name-search {
    font-weight: bold;
    color: #ff1493;
    margin-right: 5px;
    font-size: 15px;
  }
  
  .divider {
    margin: 0 8px;
    color: #666;
  }
  
  .product-description {
    color: #ccc;
  }
  
  .product-wear {
    color: #888;
    margin-left: 5px;
  }
  
  .product-price-search {
    font-size: 16px;
    font-weight: bold;
    color: #ff1493;
    margin-top: 5px;
    font-size: 15px;
  }
.search-results::-webkit-scrollbar {
    width: 1px; /* Устанавливаем ширину скроллбара в 0 */
  }
  
  /* Скрываем боковой скроллбар в Firefox */
  .search-results {
    scrollbar-width: none; /* Отключаем отображение скроллбара */
  }
/* Стили для выпадающего списка поиска */

/* Корзина */
.cart-icon {
    position: relative;
    display: flex;
    align-items: center;
}

.cart-icon img {
    width: 24px; /* Увеличен размер для большего акцента */
    height: 24px;
}

.cart-count {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: #ff1493;
    color: white;
    font-size: 10px;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Кнопка регистрации и логина */
.register-button {
    color: white;
    text-decoration: none;
    font-size: 14px;
    padding: 5px 10px;
    transition: color 0.3s;
}

.register-button:hover {
    color: #ff1493;
}

.login-button {
    background-color: #ff1493;
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.login-button:hover {
    background-color: #e01170;
}

/* Профиль и выход */
.avatar-icon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.logout-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
    padding: 0 10px;
    display: flex;
    align-items: center;
}

/* Remove background color on hover */
.logout-button:hover {
    background: none;
    color: #ff1493; /* Optional: Change the color on hover */
}

/* Optional: Add focus styling if needed */
.logout-button:focus {
    outline: none;
}
/* Sticky navbar for larger screens */
@media (min-width: 769px) {
    .navbar {
      position: fixed;
      top: 0;
    }
  }
  
  /* Mobile styles (non-sticky) */
  @media (max-width: 768px) {
    .navbar {
      position: static; /* Navbar will scroll with the content */
      justify-content: center; /* Center logo and text */
      background: transparent;
      box-shadow: none;
      width: 90%;
    }
  
    /* Hide any additional navbar content except the logo */
    .navbar > *:not(.navbar-left) {
      display: none;
    }
  
    /* Adjust logo text size */
    .logo-text {
      font-size: 16px;
    }
  }
  
