.term-text h1, .term-text h2, .term-text h3, .term-text h4 {
    color: #ff008b;
    text-align: center;
}

.term-text {
    color: #e1e2e5;
    font-size: 16px;
    letter-spacing: 0.32px;
    line-height: 24px;
    overflow-x: hidden;
    position: relative;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto; /* Центрируем текст */
    background-color: #1c1e22; /* Добавим фон */
    border-radius: 8px; /* Округлим углы */
    padding-top: 90px;
}
