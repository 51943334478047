/* Контейнер для мобильных фильтров */
.filters-mobile-container {
    position: fixed;
    top: 20px; /* Отступ сверху */
    left: 0px; /* Отступ слева */
    right: 0px; /* Отступ справа */
    bottom: 0px; /* Отступ снизу */
    background-color: rgba(0, 0, 0, 0.9);
    z-index: 1000;
    padding: 20px;
    overflow-y: auto;
    color: white;
    border-radius: 10px; /* Закругляем углы для лучшего внешнего вида */
  }
  
  
  .filters-close-button {
    background-color: #ff1493;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .filter-section {
    right: 0px;
    margin-bottom: 20px;
  }
  
  .filter-header {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    cursor: pointer;
  }
  
  .price-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  .price-filter input {
    width: 45%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #444;
    background-color: #222;
    color: white;
  }
  