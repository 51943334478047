/* Base styles */
.product-list {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 80px;
}

.product-item {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.product-add-image {
  max-width: 200px;
  height: auto;
}

.product-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
}

.product-details {
  flex: 1;
  margin-right: 20px;
}

.edit-product-form {
  display: flex;
  flex-direction: column;
}

.edit-product-form input,
.edit-product-form select,
.edit-product-form textarea {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.button-admin-1 {
  padding: 10px;
  margin-right: 5px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.button-admin {
  padding: 10px;
  margin-right: 5px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 60px;
}

button:hover {
  background-color: #45a049;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .product-list {
    padding: 10px;
    max-width: 100%;
  }

  .product-item {
    padding: 15px;
  }

  .product-add-image {
    max-width: 150px; /* Smaller image on mobile */
  }

  .product-content {
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start;
  }

  .product-details {
    margin-right: 0;
    margin-bottom: 10px; /* Add spacing between stacked elements */
  }

  .edit-product-form input,
  .edit-product-form select,
  .edit-product-form textarea {
    padding: 8px;
    font-size: 0.9rem; /* Smaller font size */
  }

  .button-admin,
  .button-admin-1 {
    padding: 8px;
    font-size: 0.9rem; /* Smaller button font size */
    width: 100%; /* Full width on mobile */
    margin-left: 0; /* Remove left margin */
  }

  .product-item + .product-item {
    margin-top: 15px; /* Add more space between items on mobile */
  }
}
