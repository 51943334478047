body {
  background-color: #1a1a1a !important; /* Темный фон */
}
/* Основной контейнер декларации */
.CookieDeclaration {
  background-color: #1a1a1a !important; /* Темный фон */
  color: #ffffff !important; /* Белый текст */
  font-family: 'Arial', sans-serif !important; /* Шрифт */
  padding: 60px !important;
  margin: 20px auto;
}

/* Текстовые элементы */
.CookieDeclarationDialogText,
.CookieDeclarationIntro,
.CookieDeclarationTypeDescription {
  font-size: 1rem !important;
  line-height: 1.6 !important;
  margin-bottom: 10px !important;
  color: #ffffff !important;
}

/* Заголовки секций */
.CookieDeclarationTypeHeader {
  font-size: 1.5rem !important;
  font-weight: bold !important;
  margin-bottom: 15px !important;
  color: #ff007f !important;
}

/* Таблица декларации */
.CookieDeclarationTable {
  width: 100% !important;
  border-collapse: collapse !important;
  margin-top: 15px !important;
}

.CookieDeclarationTable th,
.CookieDeclarationTable td {
  padding: 10px !important;
  border: 1px solid #ffffff !important;
  text-align: left !important;
  font-size: 0.9rem !important;
}

.CookieDeclarationTableHeader {
  background-color: #2c2c2c !important; /* Темный фон заголовков таблицы */
  color: #ff007f !important; /* Розовый текст заголовков таблицы */
  font-weight: bold !important;
}

.CookieDeclarationTableCell {
  background-color: #333333 !important; /* Темный фон ячеек */
  color: #ffffff !important;
}

/* Ссылки */
.CookieDeclaration a {
  color: #ff007f !important;
  text-decoration: underline !important;
}

.CookieDeclaration a:hover {
  text-decoration: none !important;
  color: #ff3399 !important; /* Светлее при наведении */
}

/* Информация о состоянии согласия */
#CookieDeclarationUserStatusPanel {
  margin-top: 20px !important;
  padding: 15px !important;
  background-color: #2c2c2c !important;
  border-radius: 8px !important;
  color: #ffffff !important;
}

#CookieDeclarationUserStatusLabelConsentId,
#CookieDeclarationUserStatusLabelConsentDate {
  font-size: 0.9rem !important;
  color: #cccccc !important;
}

#CookieDeclarationChangeConsent a {
  color: #ff007f !important;
  font-weight: bold !important;
  text-decoration: none !important;
}

#CookieDeclarationChangeConsent a:hover {
  color: #ff3399 !important;
  text-decoration: underline !important;
}

