/* Фон для модального окна */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Тёмный фон, который перекрывает весь экран */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Основной контейнер с эффектом Glassmorphism и подсветкой */
.maincontainer {
  width: 400px;
  padding: 30px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 10px 30px rgba(238, 44, 44, 0.4), 0 4px 15px rgba(0, 0, 0, 0.2);
  display: grid;
  position: relative;
  animation: neonPulse 1.5s ease-in-out infinite alternate;
}

/* Анимация пульсации подсветки */
@keyframes neonPulse {
  from {
    box-shadow: 0 0 20px rgba(199, 26, 185, 0.4), 0 0 10px  rgba(199, 26, 185, 0.4);
  }
  to {
    box-shadow: 0 0 30px rgba(199, 26, 185, 0.4), 0 0 20px  rgba(199, 26, 185, 0.4);
  }
}

/* Стили для формы */
form {
  display: flex;
  flex-direction: column;
}

form h2 {
  font-size: 2rem;
  color: #ff1493;
  margin-bottom: 30px;
}

.input-field {
  position: relative;
  border-bottom: 2px solid #ccc;
  margin: 15px 0;
}

.input-field input {
  width: 100%;
  height: 40px;
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  font-size: 1rem;
  padding: 5px 0;
  transition: border-color 0.3s ease;
}

/* Подсветка при фокусе */
.input-field input:focus {
  border-color: #ff1493;
  box-shadow: 0 0 10px rgba(255, 20, 147, 0.6);
}

.input-field label {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  color: #fff;
  font-size: 1rem;
  pointer-events: none;
  transition: 0.3s ease-in;
}

.input-field input:focus ~ label,
.input-field input:valid ~ label {
  transform: translateY(-120%);
  font-size: 0.9rem;
  top: -1px;
  color: #ff1493;
  font-weight: 600;
}

.password-options {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 25px 0 35px 0;
  color: #fff;
}

.password-options label {
  display: flex;
  align-items: center;
}

#remember {
  accent-color: #fff;
}

.password-options label p {
  margin-left: 8px;
}

.maincontainer a {
  color: #ff1493;
  text-decoration: none;
}

.maincontainer a:hover {
  text-decoration: underline;
}

button {
  background-color: #ff1493;
  color: white;
  font-size: 1rem;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 3px;
  border: 2px solid transparent;
  cursor: pointer;
  transition: 0.3s ease;
}

button:hover {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: #ff1493;
  color: #fff;
}

.account-options {
  text-align: center;
  margin-top: 30px;
  color: #fff;
}

/* Кнопка закрытия модального окна */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
}
.checkbox-field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-field input[type="checkbox"] {
  margin-right: 8px;
  transform: scale(1.2); /* Make the checkbox a bit larger for better visibility */
}

.checkbox-field label {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #ede5e5;
}

.button-sign-up {
  margin-top: 20px; /* Space between checkboxes and the Sign Up button */
}