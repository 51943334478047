/* Base styles */
.add-product-form {
  background-color: #1a1a1a;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
  color: white;
  font-family: 'Roboto', sans-serif;
  padding-top: 80px;
}

.add-product-form h2 {
  color: #ff1493;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8rem;
}

.add-product-form p {
  text-align: center;
  color: #ff1493;
  margin-bottom: 15px;
}

.add-product-form form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.add-product-form input,
.add-product-form textarea,
.add-product-form select {
  background-color: #2b2b2b;
  border: none;
  border-radius: 5px;
  padding: 12px;
  font-size: 1rem;
  color: #fff;
  outline: none;
}

.add-product-form input:focus,
.add-product-form textarea:focus,
.add-product-form select:focus {
  border: 2px solid #ff1493;
}

.add-product-form textarea {
  min-height: 100px;
  resize: none;
}

.add-product-form button {
  background-color: #ff1493;
  color: white;
  border: none;
  padding: 12px;
  font-size: 1.2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-product-form button:hover {
  background-color: #e01170;
}

.add-product-form input[type="file"] {
  padding: 10px;
  background-color: transparent;
  color: #ff1493;
  cursor: pointer;
}

.add-product-form input[type="file"]::-webkit-file-upload-button {
  background: #ff1493;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-product-form input[type="file"]::-webkit-file-upload-button:hover {
  background-color: #e01170;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .add-product-form {
    padding: 15px;
    max-width: 100%; /* Full width on mobile */
    margin: 0 10px; /* Side padding for smaller screens */
  }

  .add-product-form h2 {
    font-size: 1.5rem; /* Slightly smaller title on mobile */
  }

  .add-product-form input,
  .add-product-form textarea,
  .add-product-form select {
    padding: 10px;
    font-size: 0.9rem; /* Smaller font size for input fields */
  }

  .add-product-form button {
    font-size: 1rem; /* Reduce button text size */
    padding: 10px;
  }

  .add-product-form textarea {
    min-height: 80px; /* Reduced height for mobile */
  }
  
  .add-product-form form {
    gap: 10px; /* Reduced gap between elements */
  }
}
