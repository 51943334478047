/* Full-screen background overlay */
.contact-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Modal container */
  .contact-modal {
    background-color: #1a1a1d;
    padding: 30px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    color: #e1e2e5;
    position: relative;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  /* Close button */
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: none;
    border: none;
    color: #ff008b;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Header */
  .contact-modal h2 {
    color: #ff008b;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  /* Form */
  .contact-modal form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-modal label {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 5px;
    color: #ff008b;
  }
  
  .contact-modal input,
  .contact-modal textarea {
    padding: 10px;
    border-radius: 5px;
    border: none;
    margin-bottom: 15px;
    background-color: #333;
    color: #e1e2e5;
  }
  
  .contact-modal input::placeholder,
  .contact-modal textarea::placeholder {
    color: #999;
  }
  
  /* Send button */
  .send-button {
    background-color: #ff008b;
    color: #fff;
    border: none;
    padding: 12px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .send-button:hover {
    background-color: #ff66b2;
  }
  
  /* Responsive adjustments */
  @media (max-width: 480px) {
    .contact-modal {
      padding: 20px;
    }
  
    .contact-modal h2 {
      font-size: 20px;
    }
  
    .send-button {
      font-size: 14px;
    }
  }
  