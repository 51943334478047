@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');


.navigation {
    position: relative;
    width: 100%;
    height:75px;
    background: #121a2d;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    z-index: 1000;
    right: 0px;
    left: 0px;
    border-top: 1px solid #fff; /* Белый бордюр */
    box-shadow: 
        0 0 15px rgba(255, 255, 255, 0.8),  /* Внешняя белая подсветка */
        0 0 30px rgba(255, 255, 255, 0.6),  /* Дополнительный слой белого света */
        0 0 45px rgba(255, 255, 255, 0.4);  /* Еще один слой для более яркого неонового эффекта */
    transition: box-shadow 0.3s ease-in-out;
}

.navigation ul {
    margin-right: 40px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 0px;
}

.navigation ul li {
    position: relative;
    width: 70px;
    height: 70px;
    list-style: none;
    z-index: 1;
    display: flex; /* Используем Flexbox */
    justify-content: center; /* Горизонтальное выравнивание по центру */
    align-items: center; /* Вертикальное выравнивание по центру */
}

.navigation ul li a {
    display: flex; /* Flexbox для ссылки */
    justify-content: center; /* Центрируем содержимое по горизонтали */
    align-items: center; /* Центрируем содержимое по вертикали */
    flex-direction: column; /* Ставим текст под иконкой */
    width: 100%;
    text-align: center;
    font-weight: 500;
    text-decoration: none;
}

.navigation ul li a .icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px; /* Задаем фиксированную ширину */
    height: 60px; /* Задаем фиксированную высоту */
}



.navigation ul li a .icon img {
    width: 30px; /* Задаем размеры иконки */
    height: 30px;
    display: block;
}


.navigation ul li a .text {
    position: absolute;
    color: white;
    font-weight: 400;
    font-size: 0.9em;
    letter-spacing: 0.05em;
    opacity: 0;
    transform: translateY(20px);
    transition: 0.5s;
    font-weight: 400;
}

.navigation ul li.active a .text {
    opacity: 1;
    transform: translateY(10px);
}

.navigation ul li a .circle {
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 50%;
    border: 1.8px solid #ffffff;
    transform: translateY(-37px) scale(0);
}

.navigation ul li.active a .circle {
    transition: 0.5s;
    transition-delay: 0.5s;
    transform: translateY(-37px) scale(1);
}
.avatarIcon{
    width: 37px; /* Примените меньший размер */
    height: 37px;
    font-size: 0.9em;
    margin-top:-6px;
}
.profileIcon{
    width: 30px; /* Задаем размеры иконки */
    height: 30px;
    display: block;
}
/* Индикатор появляется у активного элемента */
.indicator {
    position: absolute;
    width: 70px;
    height: 70px;
    background: linear-gradient(0deg, #e91db6, #bb88b7);
    border: 6px solid #06021b;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    transition: 0.5s;
}

.navigation ul li.active .indicator {
    display: flex; /* Показываем индикатор только у активного элемента */
    bottom: 60px; /* Поднимаем индикатор над навбаром */
}
/* Стили для количества товаров в корзине */
.cart-count {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #ff0066; /* Розовый цвет */
    color: white;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); /* Добавим тень */
    z-index: 10; /* Убедимся, что элемент сверху */
}

@media only screen and (min-width: 769px) {
    .navigation {
        display: none;
    }
    
}
