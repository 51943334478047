.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 70px 20px;
  background-color: transparent; /* Темный фон */
  color: white;
  font-family: 'Manrope', sans-serif;
  width: 100%;
}

.footer-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-logo {
  max-width: 150px;
  margin-bottom: 15px;
}

.footer-left p {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 0;
}

.footer-center {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo-comp {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}
.footer-paym-comp {
  gap: 20px;
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-menu {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 100px;
}

.footer-menu li {
  font-size: 18px;
}

.footer-menu li a, .footer-menu li button {
  color: white;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;
}

.footer-menu li a:hover, .footer-menu li button:hover {
  color: #ff1493; /* Цвет при наведении */
}

.footer-right {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}

.footer-payment-icon {
  max-width: 60px;
}

.contact-link {
  color: #ff1493;
  cursor: pointer;
  text-decoration: underline;
  font-size: 18px;
}

.contact-link:hover {
  color: #ff66b2;
}
/* Мобильная оптимизация */
@media (max-width: 768px) {
  .footer-menu li:first-child, /* Скрыть Home */
  .footer-menu li:nth-child(2) { /* Скрыть Shop */
    display: none;
  }
  .footer {
    flex-direction: column; /* Вертикальное расположение блоков */
    align-items: flex-start; /* Выравнивание по левой стороне */
    padding-top: 2px;
    padding-bottom: 100px;
  }

  .footer-left {
    max-width: 100%; /* Блок занимает всю ширину */
    margin-bottom: 20px; /* Отступы между секциями */
    text-align: left; /* Текст выравнивается по левой стороне */
  }

  .footer-logo {
    max-width: 120px; /* Уменьшаем размер логотипа */
    margin-bottom: 15px; /* Отступ под логотипом */
  }

  .footer-left p {
    font-size: 14px; /* Сохраняем маленький размер шрифта */
    line-height: 1.4;
    text-align: left; /* Текст выравнивается по левой стороне */
  }

  .footer-center {
    justify-content: flex-start;
    width: 100%; /* Блок занимает всю ширину */
    margin-bottom: 0px; /* Добавляем отступ снизу */
    margin-left: 0px;
  }

  .footer-center ul {
    display: flex;
    flex-direction: row;/* Элементы выстраиваются в колонку */
    gap: 50px; /* Отступы между элементами */
  }

  .footer-center ul li {
    text-align: left; /* Выравниваем по левой стороне */
  }

  .footer-center ul li a {
    font-size: 16px; /* Размер шрифта чуть уменьшен для мобильных устройств */
    text-align: left; /* Выравниваем текст по левой стороне */
  }


  .footer-right {
    width: 100%;
    align-items: flex-start;
    margin-bottom: 20px;
    justify-content: flex-start;
    padding-top: 50px;
    gap: 50px;
    /* flex-direction: column; */
  }

  .footer-payment {
    margin-top: 10px; /* Уменьшаем отступ сверху */
    text-align: left; /* Выравниваем текст по левой стороне */
  }
  .footer-logo-comp {
    flex: 2 1;
    display: block; 
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }
  .footer-paym-comp {
    gap: 20px;
    flex: 2 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
  }

  .footer-payment-icon {
    max-width: 80px; /* Уменьшаем размер иконок */
    display: inline-block; /* Иконки остаются в строке */
  }
}
/* Мобильная оптимизация */
@media (max-width: 480px) {
  .footer {
    flex-direction: column; /* Вертикальное расположение блоков */
    align-items: flex-start; /* Выравнивание по левой стороне */
    padding-top: 2px;
    padding-bottom: 100px;
  }

  .footer-left {
    max-width: 100%; /* Блок занимает всю ширину */
    margin-bottom: 20px; /* Отступы между секциями */
    text-align: left; /* Текст выравнивается по левой стороне */
  }

  .footer-logo {
    max-width: 120px; /* Уменьшаем размер логотипа */
    margin-bottom: 15px; /* Отступ под логотипом */
  }

  .footer-left p {
    font-size: 14px; /* Сохраняем маленький размер шрифта */
    line-height: 1.4;
    text-align: left; /* Текст выравнивается по левой стороне */
  }

  .footer-center {
    justify-content: flex-start;
    width: 100%; /* Блок занимает всю ширину */
    margin-bottom: 0px; /* Добавляем отступ снизу */
    margin-left: 0px;
  }

  .footer-center ul {
    display: flex;
    flex-direction: column; /* Элементы выстраиваются в колонку */
    gap: 10px; /* Отступы между элементами */
  }

  .footer-center ul li {
    text-align: left; /* Выравниваем по левой стороне */
  }

  .footer-center ul li a {
    font-size: 16px; /* Размер шрифта чуть уменьшен для мобильных устройств */
    text-align: left; /* Выравниваем текст по левой стороне */
  }

  .footer-right {
    width: 100%; /* Блок занимает всю ширину */
    align-items: flex-start; /* Выравнивание по левой стороне */
    margin-bottom: 20px; 
    justify-content: flex-start;/* Добавляем отступ снизу */
    padding-top: 50px;
    flex-direction: column;
  }

  .footer-payment {
    margin-top: 10px; /* Уменьшаем отступ сверху */
    text-align: left; /* Выравниваем текст по левой стороне */
  }
  .footer-logo-comp {
    flex: 2 1;
    display: block; 
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    }
    .footer-paym-comp {
      gap: 20px;
      flex: 2 1;
      display: block;
      justify-content: center;
      align-items: center;
      width: 200px;
      }

  .footer-payment-icon {
    max-width: 80px; /* Уменьшаем размер иконок */
    display: inline-block; /* Иконки остаются в строке */
  }
}
