.user-profile-page {
  min-height: 100vh; /* Ensures the page takes up the full height of the viewport */
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Allows scrolling if content is longer than the viewport */
}

.user-profile {
  padding-top: 100px;
  padding-left: 100px;
  padding-right: 40px;
  color: white;
  font-family: 'Roboto', sans-serif;
  flex-grow: 1; /* Allows the main content to grow to fill the space */
}
  .shift-right {
    margin-left: 280px;
  }
  .profile-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 50px;
    border-radius: 15px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  
  .avatar-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .avatar-image {
    width: 180px;
    height: 180px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  }
  
  .details-section {
    flex: 2;
    margin-left: 20px;
  }
  
  .profile-title {
    font-size: 36px;
    font-weight: bold;
    color: #ff1493;
    margin-bottom: 20px;
  }
  
  .details-info {
    font-size: 18px;
  }
  
  .info-field {
    margin-bottom: 10px;
  }
  
  .info-label {
    font-weight: bold;
    color: rgba(255, 255, 255, 0.7);
  }
  
  .info-value {
    color: #ffffff;
    margin-left: 10px;
  }
  
  .info-value a {
    color: #ff1493;
    text-decoration: none;
  }
  
  .info-value a:hover {
    text-decoration: underline;
  }
  
  .edit-button {
    margin-left: auto;
  }
  
  .edit-button button {
    background-color: #ff1493;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
  }
  
  .edit-button button img {
    width: 20px;
    height: 20px;
  }
  
  .edit-button button:hover {
    background-color: #e01170;
  }
  
  .transaction-section {
    padding: 20px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  }
  
  .transaction-title {
    font-size: 28px;
    font-weight: bold;
    color: #ff1493;
    margin-bottom: 10px;
  }
  
  .no-transaction {
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
  }
/* Общий стиль для всех полей ввода */
.user-input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  font-size: 16px;
  margin-top: 5px;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.user-input:focus {
  border-color: #ff1493;
  background-color: rgba(255, 255, 255, 0.2);
}

.user-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.admin-panel-button {
  position: relative;
  margin-top: 20px;
  text-align: center;
}

/* Ensure the container of the buttons has relative positioning */
.button-row {
  position: relative;
  display: flex;
  gap: 10px; /* Space between buttons */
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}

.admin-link {
  background-color: #ff1493;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.admin-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #1a1033;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 10px 0;
  width: 160px;
  margin-top: 8px;
  z-index: 10;
}

.admin-dropdown-link {
  display: block;
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.2s;
}

.admin-dropdown-link:hover {
  background-color: #ff1493;
  color: white;
}
.receive-button {
  display: inline-block;
}

.loading-gif {
  display: block;
  margin: 0 auto;
}


.admin-link,
.acc-logout-button {
  background-color: #ff1493;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.admin-link:hover,
.acc-logout-button:hover {
  background-color: #e01170;
}

.mobile-logout-button {
  margin-top: 20px;
  text-align: center;
}

/* Стили для текста при просмотре (не редактирование) */
.info-value {
  font-size: 16px;
  line-height: 1.6;
  color: #ffffff;
  background-color: transparent;
  word-break: break-word; /* Перенос слов при переполнении */
  white-space: normal;
}

.info-value input {
  display: block;
  margin-top: 5px;
}

/* Кнопки редактирования и сохранения */
.edit-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}

.edit-button button {
  background-color: #ff1493;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.edit-button button img {
  width: 20px;
  height: 20px;
}

.edit-button button:hover {
  background-color: #e01170;
}


.transaction-items {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

/* Стили для карточек внутри transaction-items */
.transaction-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  width: calc(33.333% - 16px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.transaction-item:hover {
  transform: translateY(-5px);
}
.error-popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffcccc;
  color: #cc0000;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.error-popup p {
  margin: 0;
  padding-bottom: 10px;
}

.error-popup button {
  background-color: #cc0000;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
}

.product-game-image-us {
  height: 70%;
  object-fit: contain;
  width: 70%;
}
@media (max-width: 768px) {
  .user-profile {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .transaction-items {
    width: calc(50% - 8px);
  }
  .user-profile-page {
    padding-bottom: 100px; /* Adds bottom padding to avoid cut-off content */
  }

  .profile-container {
    flex-direction: column;
    align-items: flex-start; /* Выровняем элементы по левой стороне */
    text-align: left; /* Текст тоже по левой стороне */
    padding: 15px;
  }

  .avatar-section {
    justify-content: center;
    margin-bottom: 20px;
    width: 100%; /* Аватар на всю ширину */
  }

  .avatar-image {
    width: 120px; /* Уменьшаем размер аватара */
    height: 120px;
    margin: 0 auto; /* Центрируем аватар */
  }

  .details-section {
    margin-left: 0;
    width: 100%; /* Ширина блока с текстом на 100% */
  }

  .profile-title {
    font-size: 24px; /* Уменьшаем размер заголовка */
    margin-bottom: 15px;
  }

  .details-info {
    font-size: 16px; /* Уменьшаем общий размер текста */
  }

  .edit-button {
    margin: 20px 0 0 auto;
    width: auto; /* Оставляем авто ширину */
    display: flex;
    justify-content: flex-end;
  }

  .edit-button button {
    padding: 10px;
    font-size: 14px; /* Уменьшаем текст кнопки */
  }

  .transaction-section {
    margin-top: 20px;
    padding: 15px;
    box-shadow: none; /* Убираем лишние тени для мобильных устройств */
  }

  .transaction-title {
    font-size: 22px; /* Уменьшаем размер заголовка для мобильных */
  }

  .no-transaction {
    font-size: 14px; /* Уменьшаем размер текста */
  }
  .product-game-image-us {
    height: 70%;
    width: 50%;
  }
}

@media (max-width: 480px) {
  .avatar-image {
    width: 100px; /* Еще больше уменьшаем размер аватара для очень маленьких экранов */
    height: 100px;
  }
  .transaction-items {
    width: 100%;
  }
  .profile-title {
    font-size: 20px; /* Еще больше уменьшаем размер заголовка */
  }

  .edit-button button {
    font-size: 12px; /* Еще больше уменьшаем текст кнопки */
    padding: 8px;
  }
}
