.product-details-page {
  padding-top: 100px;
  padding-left: 100px;
  padding-right: 40px;
  padding-bottom: 100px;
  background: transparent;
  color: white;
  margin-left: 0;
  border-radius: 15px;
}

.product-details-container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.product-details-banner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 20px;
}

.product-details-info {
  flex: 1.5;
  padding: 20px;
}

.product-details-title {
  font-size: 2rem;
  font-weight: bold;
  color: #ff1493;
  margin-bottom: 20px;
}

.product-platform {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.product-game-quality-tag {
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 0.8rem;
  margin-bottom: 15px;
}

.product-info-tags {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.product-info-tag {
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 0.9rem;
  color: white;
  background-color: #333;
}

.product-info-tag.red {
  background-color: #ff1493;
}

.product-studio, .product-quantity {
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.product-price-section {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 2rem;
  margin-bottom: 20px;
}

.product-price {
  color: #fff;
}

.product-discount {
  color: #e01170;
}

.product-details-actions {
  display: flex;
  gap: 10px; /* Пространство между кнопками */
  margin-top: 20px;
}

.quick-buy-button, .add-to-cart-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #ff1493;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.quick-buy-button svg, .add-to-cart-button svg {
  width: 20px;
  height: 20px;
}

.quick-buy-button:hover, .add-to-cart-button:hover {
  background-color: #e01170;
}

.add-to-cart-button {
  background-color: #28a745;
}

.add-to-cart-button:hover {
  background-color: #218838;
}


.product-details-image-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent; /* Темный фон для предмета */
  border-radius: 10px;
  padding: 20px;
  max-width: 600px; /* Ограничиваем ширину */
  height: auto;
  position: relative;
}

.product-cover {
  width: 50%;
  height: 50%;
  object-fit: contain; /* Изображение не выходит за границы */
  max-height: 400px; /* Ограничиваем максимальную высоту */
}

.view-in-game-button {
  background-color: transparent;
  border: none;
  color: #00f;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.view-in-game-button i.icon-eye {
  margin-right: 5px;
}




/* Десктопная версия остается без изменений */

/* Мобильная оптимизация */
@media (max-width: 768px) {
  .product-details-page {
    padding: 20px; /* Уменьшаем отступы для мобильных устройств */
    padding-top: 100px;
    padding-right: 60px;
  }

  .product-details-container {
    flex-direction: column; /* Элементы располагаются вертикально */
    gap: 20px;
  }

  .product-details-banner {
    flex-direction: column; /* Элементы баннера также располагаются вертикально */
  }

  .product-details-info {
    padding: 10px; /* Уменьшаем отступы */
    text-align: left; /* Выровняем текст по правой стороне */
  }

  .product-details-title {
    font-size: 1.8rem; /* Уменьшаем размер заголовка */
  }

  .product-price-section {
    justify-content: flex-start; /* Выравнивание цен по правой стороне */
    font-size: 1.5rem; /* Уменьшаем размер текста */
  }

  .product-details-actions {
    flex-direction: column; /* Кнопки располагаются вертикально */
    width: 100%; /* Кнопки занимают всю ширину */
    gap: 10px;
    align-items: flex-start; /* Выравниваем кнопки по правой стороне */
  }

  .quick-buy-button, .add-to-cart-button {
    width: auto; /* Оставляем ширину кнопки динамичной */
    padding: 8px 16px; /* Уменьшаем отступы кнопок для мобильных */
    font-size: 14px; /* Уменьшаем размер текста в кнопках */
  }

  .product-details-image-container {
    max-width: 100%; /* Изображение занимает всю ширину */
    padding: 0; /* Убираем отступы */
  }

  .product-cover {
    width: 100%; /* Изображение на всю ширину */
    max-height: 300px; /* Ограничиваем максимальную высоту */
  }

  .view-in-game-button {
    justify-content: flex-start; /* Выровняем кнопку "Посмотреть в игре" по правой стороне */
  }
}