.privacy-text h1, .privacy-text h2 {
    color: #ff008b;
    text-align: center;
}

.privacy-text {
    color: #e1e2e5;
    font-size: 16px;
    letter-spacing: 0.32px;
    line-height: 24px;
    overflow-x: hidden;
    position: relative;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #1c1e22;
    border-radius: 8px;
    padding-top: 90px;
}
